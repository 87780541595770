import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { rfidStyle } from '../rfidStyle';
import bigUp from '../../../assets/icons/bigUp.svg';
import bigDown from '../../../assets/icons/bigDown.svg';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import clsx from 'clsx';

export const FAQComponent = ({ isLost = false }) => {
  const [showHide, setShowHide] = useState(isLost ? false : true);
  const { i18n } = useTranslation();
  const classes = rfidStyle();

  const text = `${i18n.t('card_lost_damage_answer1')} <img
  src=${deleteIcon}
  width='20px'
  height='20px'
/> ${i18n.t('card_lost_damage_answer2')}`;

  return (
    <Grid item xs={12}>
      <Grid>
        <Typography className={classes.h6}>{i18n.t('rfid_FAQs')}</Typography>
        <Typography
          className={clsx(classes.FAQ, classes.subtitle1)}
          onClick={() => setShowHide(!showHide)}
        >
          {isLost
            ? i18n.t('card_lost_damage_question')
            : i18n.t('rfid_charger_question')}
          <img
            src={showHide ? bigUp : bigDown}
            width='24px'
            height='24px'
            style={{ cursor: 'pointer' }}
            alt={i18n.t('close_faq')}
          />
        </Typography>
        {showHide && (
          <Typography
            className={classes.body1}
            style={{ color: '#6B7684', marginBottom: '15px' }}
          >
            {isLost ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></div>
            ) : (
              i18n.t('rfid_charger_answer')
            )}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
