import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Auth } from 'aws-amplify';
import { parsePhoneNumberWithPlus } from '../../util/js-utility';
import { showSnackBar } from '../../util/toast.utility';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { useNavigate } from 'react-router-dom';
import { CodeVerification } from '../code-verification/code-verification.component';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../types/routes.enum';
import { getTheme } from '../../stores/selectors/theme.selector';

import { useAuth } from '../../hooks/useAuth';
import { useDomain } from '../../hooks/useDomain';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  useAddPayMethodMutation,
  useLazyFetchListOfPayMethodsQuery,
} from '../../services/payment.api';
import RegisterForm from './register-form.component';
import { RegisterMode } from './types/register-mode-enum';
import { getIsEmail } from '../login/utils';
import {
  FetchChargerRequest,
  useFetchChargerDetailQuery,
} from '../../services/chargers.api';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import NetworkLogo from '../ui-components/network-logo.component';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    margin: 'auto',
  },
}));

const Register = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const auth = useAuth();
  const { domain } = useDomain();
  const location = useLocation();

  const chargerName = new URLSearchParams(location.search).get('charger');

  const association = new URLSearchParams(location.search).get('associationId');

  const [registerView, setRegisterView] = useState(RegisterMode.SIGNUP_FORM);

  const [newUser, setNewUser] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const theme = useSelector(getTheme);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [registrationForm, setRegistrationForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isTermsAccepted: false,
  });
  const { data: chargerDetail } = useFetchChargerDetailQuery({
    chargerName: chargerName,
  } as FetchChargerRequest);

  const [triggerFetchPayment] = useLazyFetchListOfPayMethodsQuery();

  const [triggerAddPayMethod] = useAddPayMethodMutation();

  // We do not send SMS to non +1 phone numbers on the backend, so this defaults
  // the user's login to their email in those cases.
  let loginUsername: string;
  let isLoginUsernameAnEmail: boolean;
  const parsedPhoneNumber = parsePhoneNumberWithPlus(
    registrationForm.phoneNumber,
  );
  if (parsedPhoneNumber.startsWith('+1')) {
    loginUsername = parsePhoneNumberWithPlus(parsedPhoneNumber);
    isLoginUsernameAnEmail = false;
  } else {
    loginUsername = registrationForm.email;
    isLoginUsernameAnEmail = true;
  }

  const isHomeId = useMemo(() => {
    return location?.pathname
      ?.toLowerCase()
      .includes(`${ROUTES.REGISTER_HOME_ID}`);
  }, [location]);

  useEffect(() => {
    if (auth.isLogined) {
      if (chargerName) {
        navigate(`/${chargerName}`);
      } else if (!isHomeId) {
        navigate(`/app/${domain}/${ROUTES.ACCOUNT}`);
      }
    }
  }, [chargerName, auth.isLogined]);

  const validateForm = () => {
    if (
      !registrationForm.firstName ||
      !registrationForm.lastName ||
      !getIsEmail(registrationForm.email) ||
      !registrationForm.phoneNumber ||
      !registrationForm.isTermsAccepted
    ) {
      // showSnackBar(i18n.t('Please_fill_up_all_fields'));
      return false;
    }
    return true;
  };

  const signIn = () => {
    const route = isHomeId
      ? association
        ? `/app/${domain}/${ROUTES.LOGIN_HOME_ID}?associationId=${association}`
        : `/app/${domain}/${ROUTES.LOGIN_HOME_ID}`
      : chargerName
      ? `/app/${domain}/${ROUTES.LOGIN}?charger=${chargerName}`
      : `/app/${domain}/${ROUTES.LOGIN}`;
    navigate(route);
  };

  const signUpAwsCognito = async () => {
    await auth.signUp(
      registrationForm.firstName,
      registrationForm.lastName,
      registrationForm.email,
      parsedPhoneNumber,
    );
  };

  const registerUser = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      if (isHomeId) {
        let stripeResponse;

        stripeResponse = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

        if (stripeResponse.error) {
          showSnackBar(stripeResponse.error.message);
          setLoading(false);
          return;
        } else {
          setPaymentMethod(stripeResponse.paymentMethod);
        }
      }

      await signUpAwsCognito();
      // Retry if the generated password didn't succeed the Cognito password rule

      const user = await Auth.signIn(loginUsername);

      // Just to be able to set the clientMetadata isEmail flag
      await Auth.sendCustomChallengeAnswer(user, loginUsername, {
        isEmail: isLoginUsernameAnEmail.toString(),
      });

      setNewUser(user);
      setRegisterView(RegisterMode.VERIFY_CODE);
    } catch (error) {
      setLoading(false);
      console.log('error:', error);
      // If the email or phone number already exists in our backend
      if (
        error.code === 'UserLambdaValidationException' &&
        error.message ===
          'PreSignUp failed with error email address already exists.'
      ) {
        showSnackBar(i18n.t('account_exists_email_or_phone'));
      } else {
        showSnackBar(error.message);
      }
    }
  };

  const confirmRegistration = async () => {
    if (isHomeId) {
      try {
        await triggerAddPayMethod({
          paymentMethodId: paymentMethod.id,
          isDefault: true,
        }).unwrap();
        const responseTriggerFetchPayment = await triggerFetchPayment();
        if (responseTriggerFetchPayment.data?.payload?.length > 0) {
          const url = association
            ? `/app/${domain}/${ROUTES.ASSOCIATION}?associationId=${association}`
            : `/app/${domain}/${ROUTES.ASSOCIATION}`;
          navigate(url);
        }
      } catch (error) {
        console.error('rejected AddPayMethod', error);
        setLoading(false);
        showSnackBar(i18n.t('Something_went_wrong'));
      }
    }

    setLoading(false);
    showSnackBar(i18n.t('Registration_successful'));
  };

  const handleInputChange = (key, value) => {
    setRegistrationForm({ ...registrationForm, [key]: value });
  };

  const handleClosebutton = () => {
    navigate(`/${chargerName}`);
  };

  return (
    <ViewWrapper>
      <div className={classes.wrapper}>
        <BackButtonHeader
          isSpaceBetweenAlign
          onBackArrowClicked={handleClosebutton}
          companyIcon={<NetworkLogo height='28px' width='auto' />}
        />

        {registerView === RegisterMode.SIGNUP_FORM && (
          <RegisterForm
            isHomeId={isHomeId}
            registrationForm={registrationForm}
            loading={loading}
            signIn={signIn}
            registerUser={registerUser}
            handleInputChange={handleInputChange}
            validateForm={validateForm}
            countryCode={
              chargerDetail?.location?.isoCountryCode.toLowerCase() || 'ca'
            }
          />
        )}

        {registerView === RegisterMode.VERIFY_CODE && (
          <CodeVerification
            theme={theme}
            phoneNumberOrEmailLogin={loginUsername}
            loginIsEmail={isLoginUsernameAnEmail}
            codeSentToEmail={isLoginUsernameAnEmail}
            chargerName={chargerName}
            user={newUser}
            close={confirmRegistration}
          />
        )}
      </div>
    </ViewWrapper>
  );
};

export default Register;
