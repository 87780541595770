import { makeStyles } from '@mui/styles';

export const authStyles = makeStyles(() => ({
  authWrapper: {
    padding: 24,
    maxWidth: 425,
  },
  formInput: {
    marginTop: 16,
    marginBottom: 10,
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#202223',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#202223',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#202223',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#F2F4F6',
      height: '48px',
      boxSizing: 'border-box',
    },
  },
  mt8: {
    marginTop: '8px',
  },
  mt16: {
    marginTop: '16px',
  },
  mt32: {
    marginTop: '32px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    zIndex: -1,
  },
  description: {
    marginTop: 16,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#6B7684',
  },
  size14: {
    fontSize: 12,
  },
  termsDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: '#6B7684',
    lineHeight: '20px',
    fontFamily: 'Inter',
  },
  cardwrapper: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    padding: '16px',
  },
  primaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  secondaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  ivySecondartyText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 26,
    fontWeight: 600,
    lineHeight: '28px',
  },
  ivyDescText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  MT24: {
    marginTop: 24,
  },
  inputWrapper: {
    border: '2px solid transparent',
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
      border: '2px solid #F2F4F6',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
      border: '2px solid #F2F4F6',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
    '& .Mui-focused': {
      border: '2px solid #000000',
    },
  },
  phoneInput: {
    '& .react-tel-input .arrow': {},
    '& .react-tel-input': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    '& .react-tel-input .form-control': {
      marginLeft: 8,
      width: '100%',
      height: 48,
      padding: '12px 16px',
      border: '2px solid transparent',
      background: '#F2F4F6',
    },
    '& .react-tel-input .form-control:focus': {
      border: '2px solid #000000',
    },
    '& .react-tel-input .flag-dropdown': {
      position: 'relative',
      border: '2px solid transparent',
      borderRadius: '4px',
    },
    '& .react-tel-input .selected-flag': {
      width: 44,
      border: '2px solid transparent',
      background: '#F2F4F6',
    },
    '& .react-tel-input .flag-dropdown .open': {
      width: 44,
      border: '2px solid #000000',
      borderRadius: '4px',
      boxSizing: 'border-box',
      background: '#F2F4F6',
    },
  },

  termsWrapper: {
    marginTop: '6px',
    maxWidth: 425,
  },
  callSupportBtn: {
    display: 'flex',
    margin: '32px 0px 24px 0px',
    justifyContent: 'center',
    height: '26px',
    alignItems: 'center',
  },
  absolute: {
    position: 'absolute',
  },
  formWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    maxWidth: 425,
  },
  signUpHeader: {
    textAlign: 'center',
    fontSize: '26px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '28px',
    color: '#202223',
  },
  isHomeId: {
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    height: 55,
    boxSizing: 'border-box',
    padding: '17px 12px',
    paddingLeft: 12,
  },
  privacyPolicy: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    fontFamily: 'Inter',
    padding: 0,
    textTransform: 'inherit',
  },
  andText: {
    fontWeight: 400,
    color: '#202223',
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '20px',
    position: 'relative',
    left: '-6px',
    top: '2px',
  },
  termsOfService: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    fontFamily: 'Inter',
    padding: 0,
    left: '-12px',
    textTransform: 'inherit',
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  },
  contentBox: {
    display: 'flex',
    width: '335px',
    alignItems: 'flex-start',
    gap: '16px',
  },
  contentDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
  },
  contentHeader: {
    paddingLeft: '5px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: '#202223',
  },
  contentItem: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#6B7684',
    paddingLeft: '24px',
  },
  icon: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    gap: '10px',
    width: '24px',
    height: '24px',
    borderRadius: '32px',
  },
  footerBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    backgroundColor: '#F2F4F6',
  },
  footerDetailsBox: {
    display: 'flex',
    gap: '5px',
  },
  typographyClass: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'center',
  },
  loginHeader: {
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '32px',
    color: '#202223',
  },
  loginBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  doNotHaveAccount: {
    color: '#6B7684',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
  },
  signUpBtn: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    fontFamily: 'Inter',
    textTransform: 'inherit',
    padding: '0px',
  },
  loginBtn: {
    color: '#ffffff',
    width: '76px',
    height: '48px',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'inherit',
    padding: '12px 16px',
  },
  verifyHeader: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
  },
  verifySubHeader: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  formatNumber: {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
  doNotReceiveText: {
    color: '#6B7684',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
  },
  doNotReceiveBtn: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    fontFamily: 'Inter',
    textTransform: 'inherit',
    padding: '0px',
  },
  submitSignInBtn: {
    color: '#ffffff',
    bottom: 0,
    height: '48px',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'inherit',
    padding: '12px 16px',
  },
}));
