import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { TestCompanyStatus } from '../charger/dto/charger.dto';
import { authStyles } from './authStyles';

interface HaveQuestionsProps {
  chargerDetail: any; // Required
  createZendeskTicket?: () => any; // Optional function to create a zendesk ticket for charger support
  style?: any;
}

const HaveQuestions = ({
  chargerDetail,
  createZendeskTicket,
  style = {},
}: HaveQuestionsProps) => {
  const { i18n } = useTranslation();
  const classes = authStyles();
  const theme = useSelector(getTheme);

  const callSupport = () => {
    if (createZendeskTicket) createZendeskTicket();
    window.location.href = `tel:${chargerDetail.contactInfo}`;
  };

  const chargerNotBelongsToTestCompany = () => {
    return (
      chargerDetail.testCompany === TestCompanyStatus.NOT_FROM_TEST_COMPANY ||
      !chargerDetail.testCompany
    );
  };

  const whiteLabelledCharger = () => {
    return (
      chargerDetail.whiteLabellingName === 'ChargeLab' ||
      chargerDetail.whiteLabellingName === 'Eaton'
    );
  };

  const redirectHelpCenter = () => {
    if (chargerDetail.whiteLabellingName === 'ChargeLab') {
      window.open(
        'https://chargelab.zendesk.com/hc/en-us/sections/25563504258843-Charging-Basics',
      );
    } else
      window.open(
        'https://eatoncharging.zendesk.com/hc/en-ca/sections/27529579005339-How-to-start-a-charging-session',
      );
  };

  return (
    <div>
      {!whiteLabelledCharger() && !chargerNotBelongsToTestCompany() ? null : (
        <Box className={classes.footerBox} style={{...style}}>
          <Typography
            variant='h3'
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            {i18n.t('have_questions')}
          </Typography>
          <Box className={classes.footerDetailsBox}>
            {whiteLabelledCharger() && (
              <>
                <Typography
                  variant='h3'
                  style={{ color: '#4E5968' }}
                  className={classes.typographyClass}
                >
                  {i18n.t('visit_help')}
                </Typography>
                <Typography
                  variant='h3'
                  style={{
                    color: theme.navigationSelectedColor,
                    cursor: 'pointer',
                  }}
                  className={classes.typographyClass}
                  onClick={redirectHelpCenter}
                >
                  {i18n.t('help_center')}
                </Typography>
              </>
            )}
            {whiteLabelledCharger() && chargerNotBelongsToTestCompany() && (
              <Typography
                variant='h3'
                style={{ color: '#4E5968' }}
                className={classes.typographyClass}
              >
                {i18n.t('or')}
              </Typography>
            )}
            {chargerNotBelongsToTestCompany() && (
              <Typography
                variant='h3'
                style={{
                  color: theme.navigationSelectedColor,
                  cursor: 'pointer',
                }}
                className={classes.typographyClass}
                onClick={callSupport}
              >
                {i18n.t('Call_support')}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default HaveQuestions;
