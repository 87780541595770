export enum ROUTES {
  ROOT = '/',
  FOUR_0_FOUR = '404',
  EXPLORE = 'explore',
  PAYMENTS = 'payments',
  ACCOUNT = 'account',
  PROFILE = 'profile',
  CHARGER_ACTIVE = 'charger/active',
  RECEIPTS = 'receipts',
  HISTORY = 'history',
  LOGIN = 'login',
  REGISTER = 'register',
  ASSOCIATION = 'association',
  LOGOUT = 'logout',
  HOME_ID = 'submit-id',
  TECHNICIAN_VIEW = 'technician-view',
  LANGUAGE = 'language',
  NO_PAGE_FOUND = '*',
  RFID = 'rfid',
  PLUG_AND_CHARGE_KEYS = 'plug-and-charge-keys',
  ORDER_CARD = 'order-card',
  ACTIVATE_CARD = 'activate-card',
  DELETE_RFID = 'delete-rfid',
  ADD_RFID = 'add-rfid',
  PLUG_AND_CHARGE_ADD_VEHICLE = 'plug-and-charge-add-vehicle',
  REGISTER_HOME_ID = 'registerhomeid',
  LOGIN_HOME_ID = 'loginhomeid',
  NOTIFICATION = 'notification',
  LOGIN_INFO = 'login-info',
}

export enum SUB_ROUTES {
  IVY = 'IVY',
}
